/////////////////////////////
//                         //
//    Public attributes    //
//                         //
/////////////////////////////

const commonTemplates = require.context('./modules/', true, /\.html$/u);
commonTemplates.keys().forEach(function forEachModules(commonTemplate) {
    commonTemplates(commonTemplate);
});

/////////////////////////////

export { commonTemplates };
